<template>
    <div class="content">
        <header>
            <h1 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile py-0 my-2">{{ $t("navigation.not_found") }}</h1>
        </header>
    </div>
</template>

<script>
/**
 * NotFound view
 * Display as a 404 error page
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'NotFound',
    metaInfo: function() {
        return {
            title: `${this.$t("navigation.not_found")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				},
                {
                    name: 'robots',
                    content: 'noindex'
                }
            ]
        }
    }
}
</script>